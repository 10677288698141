import React from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { Divider, List, ListItem } from "@mui/material";
import { CuratedLocationAddress } from "../../Services/LocationsEntitiesV2";
import { Dispatch } from "../Dispatch";
import { AddressV2 } from "../../Services/MakeBookingContracts";
import DialogClose from "../Dialog/DialogClose";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import "./CuratedLocationSelector.scss";
import { ConsiderFareUpdate } from "../Condition/FareLoader";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { Api } from "../../Services/Api";

/** Dialog that lists curated locations for the selected address so that the user can select the best match in order to get an accurate fare estimate. */
export const CuratedLocationSelector: React.FC = () => {

    const locationsResult = useAppState(state => state.CuratedLocations.CuratedLocationsResult)!; // this component opens only when this is not null
    const currentIndex = useAppState(state => state.CuratedLocations.CurrentlyActiveLocationIndex);
    const isMobileView = useAppState(OptionalUI.MobileLayout);

    function LoadCuratedLocationToAddress(location: CuratedLocationAddress) {
        const selectedAddress: AddressV2 = {
            FullTextAddress: location.DisplayName,
            GeoLocation: {
                Latitude: location.Latitude,
                Longitude: location.Longitude
            },
            GoogleMapsPlaceId: location.GooglePlaceId,
            Place: location.DisplayName,
            Number: null,
            Unit: null,
            StreetName: null,
            Suburb: null,
            State: null,
            Country: null,
            PostCode: null
        }

        Dispatch.Booking.Address(currentIndex, selectedAddress);
        Dispatch.Booking.CuratedLocationAddressId(currentIndex, location.LocationAddressId);
        Dispatch.Dialog.CloseTopDialog();
        ConsiderFareUpdate();

        // send location selected feedback to server. we don't do anything with the response, hence no need to await this.
        Api.LocationV2.FeedbackCuratedLocation({
            ZoneId: location.ZoneId,
            LocationAddressId: location.LocationAddressId
        });
    }

    return (
        <div className="error-message-base-panel small-non-error-info-dialog">
            {isMobileView && <DialogClose />}
            <div className="emb-problem-panel">{locationsResult.ZoneDescription}</div>
            <List className="curated-addresses-list">
                {locationsResult.Addresses.map((location, index) => (
                    <>
                        <ListItem key={index} onClick={() => LoadCuratedLocationToAddress(location)} className="curated-addresses-list-item">
                            <div>
                                <div className="location-display-name">{location.DisplayName}</div>
                                <div className="location-zone-display-name">{locationsResult.DisplayName}</div>
                            </div>
                            <div><KeyboardArrowRightIcon /></div>                            
                        </ListItem>
                        <Divider />
                    </>                                            
                ))}
            </List>
        </div>
    );
}